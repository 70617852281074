import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import renderAst from "../utils/ast-compiler";

const GenericPage = ({ pageContext: { language, title, id }, data }) => {
  const post = data.markdownRemark;
  return (
    <Layout locale={language} title={title} pageId={id}>
      <div className="bg-white">
        <div className="max-w-screen-xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
              {post.frontmatter.title}
            </h1>
          </div>
        </div>
      </div>
      <div className="px-4  sm:px-6 lg:px-8">
      <div className="prose">{renderAst(post.htmlAst)}</div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query GenericPageQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
      }
    }
  }
`;

export default GenericPage;